<template>
  <div class="about">
    <div class="header">
      <div class="header_title">
        <div class="title">
          مۇلازىم سۇپىسىدىن تاماق بۇيرىتىشىڭىزنى قارشى-ئالىمىز
        </div>
        <div class="title">欢迎您到美滋来点菜</div>
      </div>
      <div class="position"></div>
    </div>
    <div class="body_content">
      <div class="order">
        <div class="btn" @click="url">
          <div>تاماق بۇيرۇتاي</div>
          <div>点菜</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
  methods: {
    url() {
      window.location.href = "https://m.mulazim.com/";
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "UKIJEkran";
  background-color: #f1f1f1;
}
.header {
  background-color: #139d59;
  color: #fff;
  height: 250px;
  position: relative;
}
.header .header_img img {
  width: 70px;
  height: 70px;
}
.title {
  width: 85%;
  font-size: 17px;
  font-weight: 500;
  margin: auto;
  text-align: center;
}
.header_img {
  padding: 0 5px;
}
.position {
  background-color: #238555;
  height: 15px;
  width: 96%;
  margin: auto;
  position: absolute;
  bottom: 20px;
  border-radius: 6px;
}
.body_content {
  width: 92%;
  height: 200px;
  margin: auto;
  margin-left: 4%;
  z-index: 12;
  position: absolute;
  margin-top: -28px;
  background: linear-gradient(0deg, #fff, 96%, #139d59);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.content_price {
  width: 94%;
  padding: 15px 0;
  border-bottom: 2px dashed #e6e6e6;
  margin: auto;
  text-align: center;
  color: #454545;
  font-size: 28px;
  font-weight: 500;
}
.order {
  width: 94%;
  margin: 80px auto 0;
  font-size: 16px;
  color: #434343;
}
.order_item {
  padding: 8px 5px 16px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f18a;
}
.order_list {
  text-align: center;
  font-size: 17px;
  line-height: 30px;
}
.btn {
  width: 160px;
  padding: 7px 5px;
  background: #139d59;
  border-radius: 24px;
  margin: auto;
  text-align: center;
  color: #fff;
}
</style>

<template>
  <div class="about">
    <div class="header">
      <div class="header_img"><img src="../assets/img/nan.png" /></div>
      <div class="header_title">
        <div class="title">زاكاز ئەمەلدىن قالدى</div>
        <div class="title">订单已失效</div>
      </div>
      <div class="position"></div>
    </div>
    <div class="body_content">
      <div class="order">
         <div class="order_item">
          <div class="order_list">
            <div>يەنە كېلىشىڭىزنى قارشى ئالىمىز</div>
            <div>请欢迎再来</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'about',
}
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "UKIJEkran";
  background-color: #f1f1f1;
}
.header {
  background-color: #1db476;
  color: #fff;
  height: 250px;
  position: relative;
}
.header .header_img img {
  width: 70px;
  height: 70px;
}
.title {
  font-size: 17px;
  font-weight: 600;
}
.header_img {
  padding: 0 5px;
}
.position {
  background-color: #0d995d;
  height: 15px;
  width: 96%;
  margin: auto;
  position: absolute;
  bottom: 20px;
  border-radius: 6px;
}
.body_content {
  width: 92%;
  height: 260px;
  margin: auto;
  margin-left: 4%;
  z-index: 12;
  position: absolute;
  margin-top: -28px;
  background: linear-gradient(0deg, #fff, 96%, #51be91);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.content_price {
  width: 94%;
  padding: 15px 0;
  border-bottom: 2px dashed #e6e6e6;
  margin: auto;
  text-align: center;
  color: #454545;
  font-size: 28px;
  font-weight: 500;
}
.order {
  width: 94%;
  margin: 80px auto 0;
  font-size: 16px;
  color: #434343;
}
.order_item {
    padding: 8px 5px 16px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f18a;
}
.order_list {
    text-align: center;
    font-size: 17px;
    line-height: 30px;
}
</style>

<template>
  <div class="container containerJCniXJ">
    <div class="loading" v-if="loading">
      <img src="../assets/img/loading.png" />
    </div>
    <!-- 头部 -->
    <div class="header">
      <div>زاكاز تەپسىلاتى</div>
      <div>订单详情</div>
    </div>
    <div class="headerLine"></div>

    <!-- delivery -->
    <div class="delivery-info" v-if="order.id && order.delayed">
      <div class="delivery-header">
        <div class="delivery-header-title">
          <img src="../assets/img/points.png" alt="" />
          <div class="flex-column textRight">
            <span>يەتكۈزۈش ۋاقتىنى ئۇزارتىش ئىلتىماسى</span>
            <span>配送时间延迟申请</span>
          </div>
        </div>
        <div class="points">
          <span class="points-color flex-column">
            <span>مېنۇت</span>
            <span>分钟</span>
          </span>
          <span>{{ order.delayed.delayed_duration }}</span>
        </div>
      </div>

      <div class="delivery-description">
        <div class="delivery-description-title flex-column textCenter">
          <span>سەۋەبى</span>
          <span>原因</span>
        </div>
        <div class="delivery-description-content">
          {{ order.delayed.reason_delay }}
        </div>
      </div>
      <div class="delivery-time">
        <div class="order-summary-total">
          <div class="order-summary-title flex-column">
            <span>ئەسلىي يەتكۈزۈش ۋاقتى</span>
            <span>原配送时间</span>
          </div>
          <div class="order-summary-value">
            <span>{{
              formattedDate(order.delayed.original_booking_time)
            }}</span>
            <span class="points-number">+</span>
            <span class="points-number">{{
              order.delayed.delayed_duration
            }}</span>
          </div>
        </div>
        <div class="order-summary-total">
          <div class="order-summary-title flex-column">
            <span>ھازىرقى يەتكۈزۈش ۋاقتى</span>
            <span>现配送时间</span>
          </div>
          <div class="order-summary-value">
            <span>{{ formattedDate(order.delayed.new_booking_time) }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单总结 -->
    <div
      class="action-buttons"
      v-if="order.delayed && order.delayed.agree_state == 1"
    >
      <button
        class="cancel-button textCenter"
        @click="callPhone(order.admin_phone)"
      >
        <img src="../assets/img/phone.png" alt="Phone" />
        <span class="flex-column">
          <span>ئالاقىلىشاي</span>
          <span>联系</span>
        </span>
      </button>
      <button
        class="confirm-button flex-column textCenter"
        @click="submitHandler"
      >
        <div>قوشۇلىمەن</div>
        <div>同意</div>
      </button>
    </div>

    <!-- 订单信息 -->
    <div class="order-summary" v-if="order.foods">
      <div
        class="item-details"
        v-for="(item, index) in order.foods"
        :key="index"
      >
        <div class="details">
          <div class="name">
            <div class="details-title">
              {{ item.name_zh }}{{ item.name_ug }}
            </div>
            <div class="details-price">
              <span class="order-summary-price">￥</span
              ><span>{{ item.price }}</span>
            </div>
          </div>
          <div class="number">
            <span>x</span><span>{{ item.number }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 订单详情 -->
    <div class="order-summary-body">
      <div class="order-summary-total">
        <div class="order-summary-title flex-column order-summary-width">
          <span>قاچىسى ھەققى</span>
          <span>包装费</span>
        </div>
        <div class="order-summary-value">
          <span class="order-summary-price">￥</span
          ><span>{{ order.lunch_box_fee }}</span>
        </div>
      </div>

      <div class="order-summary-total" v-if="order.shipment">
        <div class="order-summary-title flex-column order-summary-width">
          <span>كىرا</span>
          <span>配送费</span>
        </div>
        <div class="order-summary-value">
          <span class="order-summary-price">￥</span
          ><span>{{ order.shipment }}</span>
        </div>
      </div>

      <div class="order-summary-total">
        <div
          class="order-summary-title order-summary-block flex-column order-summary-width"
        >
          <span>جەمئىي</span>
          <span>总金额</span>
        </div>
        <div class="order-summary-value order-summary-time">
          <span class="order-summary-price">￥</span
          ><span>{{ order.price }}</span>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-body">
          <p>{{ modelContent }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "OrderSummary",
  data() {
    return {
      order: {},
      showModal: false, // 模态框显示状态
      loading: true,
      modelContent: "", // 模态框内容
    };
  },
  created() {
    this.getList(); // 获取订单列表
  },
  methods: {
    formattedDate(val) {
      return dayjs(val).format("MM-DD HH:mm");
    },
    callPhone(phone) {
      window.location.href = `tel:${phone}`;
    },
    getList() {
      this.loading = true;
      const that = this;
      this.$axios({
        method: "get",
        url: this.$url + "order-detail-prolong",
        params: { rid: this.$route.query.rid },
      })
        .then((res) => {
          if (res.data.status == 200) {
            that.order = res.data.data;
          }
          that.loading = false;
        })
        .catch((err) => {
          console.log(err);
          that.loading = false;
        });
    },
    closeModal() {
      this.showModal = false;
    },
    confirmAction() {
      // 确认
      this.closeModal();
    },
    submitHandler() {
      const that = this;
      that.loading = true;
      that
        .$axios({
          method: "post",
          url: this.$url + "order-detail-prolong-confirm",
          data: { rid: this.$route.query.rid },
        })
        .then((res) => {
          that.modelContent = res?.data?.msg || "قايتا سىناڭ";
          that.loading = false;
          that.showModal = true;
          setTimeout(() => {
            that.showModal = false;
            that.getList();
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          that.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  background: #dcf1ed;
  box-sizing: border-box;
  height: 100vh;
  overflow-x: auto;
}

.header {
  background-color: #4ac45d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  height: 60px;
  font-size: 17px;
}
.headerLine {
  height: 60px;
}

.status-icons {
  display: flex;
  align-items: center;
}

.order-summary {
  background-color: white;
  margin: 10px 10px 0px 10px;
  padding: 12px 12px 5px;
  border-radius: 10px 10px 0px 0px;
}
.order-summary-body {
  background-color: white;
  margin: 0px 10px 10px 10px;
  padding: 12px 12px 2px;
  border-radius: 0px 0 10px 10px;
}
.order-summary .title {
  font-size: 16px;
  color: #000;
  padding: 2px 0 8px;
  text-align: right;
}
.order-summary .bold {
  font-weight: bold;
}

.item-details {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 4px 0;
  border-bottom: 1px solid #dcf1ed;
  gap: 10px;
}
.item-details:last-child {
  border-bottom: none;
}

.item-details img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.details {
  flex-grow: 1;
}
.details .name {
  font-size: 15px;
  color: #333;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}
.details-price {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: end;
  margin-top: 4px;
  direction: ltr;
}
.details-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 85%;
  direction: rtl;
}
.details .number {
  font-size: 15px;
  color: #999;
  text-align: right;
  margin-top: 2px;
}

.price,
.final-price {
  color: #58c679;
  font-weight: bold;
}

.delivery-info {
  background-color: white;
  padding: 12px 12px 4px;
  border-radius: 10px;
  margin: 10px;
}

.delivery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  flex-direction: row-reverse;
  border-bottom: 1px solid #dcf1ed;
  padding: 6px 0 12px;
  font-size: 16px;
}
.delivery-header-title {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5px;
}
.delivery-header-title img {
  width: 18px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.points {
  color: orange;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 18px;
}
.points-number {
  color: orange;
}
.points-color {
  color: #757575;
  font-weight: normal;
  font-size: 12px;
}

.delivery-description {
  margin-top: 10px;
  line-height: 1.5;
  text-align: center;
}
.delivery-description-content {
  color: #757575;
  font-size: 14px;
  line-height: 1.5;
}
.delivery-description-title {
  color: #000;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.delivery-time {
  margin: 15px 0 0;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0;
}

.cancel-button {
  background-color: #ffffff;
  border: none;
  padding: 0px 15px;
  width: 36%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 5px;
  font-size: 17px;
  color: #000;
}
.cancel-button img {
  width: 12px;
}

.confirm-button {
  background-color: #4ac45d;
  color: white;
  border: none;
  padding: 5px;
  width: 60%;
  border-radius: 8px;
  font-size: 16px;
}
.order-summary-total {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  flex-direction: row-reverse;
  border-top: 1px solid #dcf1ed;
  align-items: center;
}
.order-summary-total-price {
  color: #e85e5e;
}

.order-summary-title {
  font-size: 17px;
  color: #757575;
}
.order-summary-block {
  color: #000;
  font-weight: bold;
}
.order-summary-time {
  font-size: 18px !important;
  color: #4ac45d !important;
  font-weight: bold;
}
.order-summary-price {
  font-size: 12px;
}
.order-summary-value {
  color: #000;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 2px;
  direction: ltr;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin-top: -100px;
}

.modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 3px 6px 0;
  font-size: 18px;
}

.modal-content .close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.modal-body {
  padding: 5;
  text-align: center;
  font-size: 16px;
}
.modal-overlay .modal-content {
  opacity: 1;
  transform: scale(1);
}
.containerJCniXJ {
  direction: rtl !important;
}
.textRight {
  text-align: right;
}
.textCenter {
  justify-content: center;
  align-items: center;
}
.order-summary-width {
  width: 92px;
}
</style>

<template>
  <div class="about">
    <div class="header">
      <div class="header_img"><img :src="order_info.pay_img" /></div>
      <div class="header_title">
        <div class="title">{{ order_info.title_ug }}</div>
        <div class="title">{{ order_info.title_zh }}</div>
        <div class="price">{{ order_info.info_ug }}</div>
        <div class="price">{{ order_info.info_zh }}</div>
      </div>
      <div class="position"></div>
    </div>
    <div class="body_content">
      <div class="content_price" v-if="order.price>0">
        ￥{{ order.price + order.lunch_box_fee + order.shipment }}
      </div>
      <div class="order">
        <div class="order_item">
          <div class="order_list">
            <div>زاكاز نومۇرى:</div>
            <div>订单编号:</div>
          </div>
          <div class="order_number">
            {{ order.order_id }}
          </div>
        </div>

        <div class="order_item" style="border-bottom: 1px solid #fff">
          <div class="order_list">
            <div>زاكاز ۋاقتى:</div>
            <div>下单时间:</div>
          </div>
          <div class="order_number">{{ order.created_at }}</div>
        </div>
      </div>
    </div>
    <div class="exit" @click="exit()">
      <div>قايتىش</div>
      <div>返回</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      order: {
        lunch_box_fee: 0,
        order_id: JSON.parse(localStorage.getItem("mulazimInfo")).order_id,
        price: 0,
        shipment: 0,
        created_at: JSON.parse(localStorage.getItem("mulazimInfo")).created_at,
      },
      order_info: {
        pay_img: require("../assets/img/xiao.png"),
        title_ug: "پۇل تۈلىنىۋاتىدۇ",
        title_zh: "正在支付",
        info_ug: "",
        info_zh: "",
      },
      order_id: "",
      is_finish_order: false,
      timer: "",
      count: 0,
    };
  },
  created() {
    let that = this;
    that.order_id = localStorage.getItem("order_id");
    if (that.$isMobile.isWX() == false) {
      that.getUrl();
      if (!that.is_finish_order) {
        that.timer = setInterval(() => {
          if (that.count > 10) {
            that.requestFail();
            return false;
          }
          that.getUrl();
          that.count++;
        }, 3000);
      }
    }
    if (that.$isMobile.isWX() == true) {
      that.order_info = {
        pay_img: require("../assets/img/pay.png"),
        title_ug: "مۇۋاپىقىيەتلىك بولدى",
        title_zh: "支付成功",
        info_ug: "سېتىۋالغىنىڭىزغا رەھمەت",
        info_zh: "感谢您的购买",
      };
      that.order = JSON.parse(localStorage.getItem("mulazimInfo"));
    }
  },
  methods: {
    requestFail() {
      let that = this;
      let orders = JSON.parse(localStorage.getItem("mulazimInfo"));
      that.order = {
        lunch_box_fee: 0,
        order_id: orders.order_id,
        price: 0,
        shipment: 0,
        created_at: orders.created_at,
      };
      that.order_info = {
        pay_img: require("../assets/img/nan.png"),
        title_ug: "پۇل تۈلەش مەغلۇپ بولدى",
        title_zh: "支付失败",
        info_ug: "",
        info_zh: "",
      };
      clearInterval(that.timer);
    },
    getUrl() {
      let that = this;
      that.$axios
        .get(that.$url + "pay-query", {
          params: {
            rid: that.order_id,
          },
        })
        .then((res) => {
          let orders = JSON.parse(localStorage.getItem("mulazimInfo"));
          if (res.data.status == 200) {
            (that.order_info = {
              pay_img: require("../assets/img/pay.png"),
              title_ug: "مۇۋاپىقىيەتلىك بولدى",
              title_zh: "支付成功",
              info_ug: "سېتىۋالغىنىڭىزغا رەھمەت",
              info_zh: "感谢您的购买",
            }),
            that.order = orders;
            that.is_finish_order = true;
            clearInterval(that.timer);
            return false
          }
        })
        .catch((err) => {
          clearInterval(that.timer);
          console.log(err);
        });
    },
    exit() {
      let that = this;
      that.$router.push("/?rid=" + that.order_id);
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "UKIJEkran";
  background-color: #f1f1f1;
}
.header {
  background-color: #1db476;
  color: #fff;
  height: 250px;
  position: relative;
}
.header .header_img img {
  width: 80px;
  height: 80px;
}
.title {
  font-size: 17px;
  font-weight: 600;
}
.price {
  color: #eeeeee;
  font-size: 14px;
  font-weight: 500;
}
.header_img {
  padding: 0 5px;
}
.position {
  background-color: #0d995d;
  height: 15px;
  width: 96%;
  margin: auto;
  position: absolute;
  bottom: 20px;
  border-radius: 6px;
}
.body_content {
  width: 92%;
  height: 260px;
  margin: auto;
  margin-left: 4%;
  z-index: 12;
  position: absolute;
  margin-top: -28px;
  background: linear-gradient(0deg, #fff, 96%, #51be91);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.content_price {
  width: 94%;
  padding: 15px 0;
  border-bottom: 2px dashed #e6e6e6;
  margin: auto;
  text-align: center;
  color: #454545;
  font-size: 28px;
  font-weight: 500;
}
.order {
  width: 94%;
  margin: 30px auto 0;
  font-size: 16px;
  color: #434343;
}
.exit {
  background-color: #0d995d;
  color: #fff;
  border-radius: 30px;
  padding: 8px 0;
  text-align: center;
  width: 70%;
  margin: 280px auto 0;
}

.order_item {
  padding: 8px 5px 8px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f18a;
}
.order_list {
  flex: 1.6;
  text-align: right;
  font-size: 16px;
}
.order_number {
  padding: 0 6px;
  flex: 3;
  color: #373737;
  font-size: 15px;
  word-break: break-all;
}
</style>

<template>
  <div class="about"></div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      order_id: ''
    }
  },
  created() {
    this.getUrl();
    this.order_id = localStorage.getItem('order_id')
  },
  methods: {
    getUrl() {
      let that = this;
      this.$axios({
        method: "post",
        url: that.$url + "login",
        data: { code: that.$route.query.code },
      })
        .then((res) => {
          if (res.data.status != 200) {
            localStorage.setItem("setLogin", null);
            return
          }
          localStorage.setItem("setLogin", JSON.stringify(res.data.data));
          that.$router.back();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
